





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-cascader-menu{
    height:400px;
    width:100%;
}
.item-width  {
    width: 48%;
    color:#409EFF;
}
.video-js .vjs-icon-placeholder {
    width: 80%;
    height: 80%;
    display: block;
}

