















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































::v-deep .el-table {
    th {
        padding: 0px ;
    }
    thead{
        height: 15px !important;
        color:rgb(71, 70, 70);
        font-size:12px;
    }
    td {
        padding: 3px ;
        font-size:11px;
    }   
}
.item-width  {
    width: 48%;
    color:#409EFF;
}
.item-width2  {
    width: 97%;
    color:#409EFF;
}
.item-width3  {
    width: 32%;
    //background: #ccc;
}
.pub_dialog {
    display: flex;
    justify-content: center;
    align-items: Center;
    overflow: hidden;
    .el-dialog {
        margin: 0 auto !important;
        height: 90%;
        overflow: hidden;
        .el-dialog__body {
            position: absolute;
            left: 0;
            top: 54px;
            bottom: 0;
            right: 0;
            padding: 0;
            z-index: 1;
            overflow: hidden;
            overflow-y: auto;
        }
    }
}
